// @ts-nocheck
//
// This file imports 3rd party dependencies that do not change regularly and are shared between multiple other packs.
// The generated vendor.js should always be loaded before other generated packs are loaded.  Eg:
//   <%= append_javascript_pack_tag 'vendor' %>
//   <%= append_javascript_pack_tag 'application' %>
//
// This is done for two main reasons:
// 1. it reduces the total generated javascript size by only including a single copy of each lib (eg jquery)
// 2. it reduces the total amount of data downloaded by the browser by allowing the rarely updated dependencies
//    to be cached while app code continues to change over time.
//
import 'jquery';
import 'jquery.devise2fa';
import 'lodash';
import 'jquery-ujs';
import 'js-cookie';
import 'magnific-popup';
import 'rails.validations';
