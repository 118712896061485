(function($) {
  $(function() {
    function Devise2fa(el, options) {
      this.sendCode = this.sendCode.bind(this);
      this.el = el;
      this.options = this._mergeOptions(options);
    }

    Devise2fa.prototype.sendCode = function() {
      this.options.invitationToken = $(this.options.invitationTokenField).val();
      this.options.phoneNumber = $(this.options.phoneNumberField).val();
      this.options.twoFactorMethod = $(this.options.twoFactorField).val();
      this.options.email2fa = $(this.options.email2faField).val();
      var self = this;

      if (this._validate()) {
        $('.alert').remove();
        let sentPhoneNumber = this.options.phoneNumber;
        const updatedPhoneNumber = $(this.options.phoneNumberField).val();
        if (sentPhoneNumber === '' || updatedPhoneNumber !== sentPhoneNumber) {
          sentPhoneNumber = updatedPhoneNumber;
        }
        return $.ajax({
          url: "/oportal/users/two_factor/create_token",
          dataType: 'json',
          data: {
            'number': sentPhoneNumber,
            'invitation_token': this.options.invitationToken,
            'two_factor_method': this.options.twoFactorMethod,
            'email_2fa': this.options.email2fa,
            'fingerprint': sessionStorage.getItem('fingerprint'),
            'dummy_value': 'not_used'
          },
          type: 'POST',
          error: function(jqXHR, textStatus, errorThrown) {
            self.el.trigger('ajaxError.devise2fa', jqXHR, textStatus, errorThrown);
            return __guardMethod__(self.options, 'error', function(o) {
              o.error(jqXHR, textStatus, errorThrown);
            });
          },

          success: function(data, textStatus, jqXHR) {
            self.el.trigger('ajaxSuccess.devise2fa', data, textStatus, jqXHR);
            return __guardMethod__(self.options, 'success', function(o) {
              o.success(data, textStatus, jqXHR);
            });
          }
        });
      }
    };

    Devise2fa.prototype._validate = function() {
      if (this.options.validate.apply(this)) {
        this.el.trigger('valid.devise2fa');
        return true;
      } else {
        this.el.trigger('invalid.devise2fa');
        return false;
      }
    }

    Devise2fa.prototype._mergeOptions = function(options) {
      options = options || {};
      var defaults = {
        verificationCodeField: '#user_verification_code',
        invitationTokenField: '#user_invitation_token',
        phoneNumberField: '#user_phone_number',
        fingerprintField: '#fingerprint',
        twoFactorField: "input[name='user[two_factor_field]']:checked",
        email2faField: "#user_email_2fa",
        validate: function() {
          if (this.options.email2fa) {
            return true
          }
          var phoneNumber = this.options.phoneNumber;
          var phoneRegEx = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
          return phoneRegEx.test(phoneNumber);
        }
      };

      return $.extend({}, defaults, options);
    }

    $.fn.devise2fa = function(options) {
      options = options || {};

      // if a string method named was passed as the first argument, merge it into the options object
      if (typeof options === 'string') {
        options = $.extend({}, (arguments[1] || {}), {method:options});
      }

      return this.each(function() {
        var devise2fa;
        if ($(this).data('devise2fa') instanceof Devise2fa) {
          devise2fa = $(this).data('devise2fa');
        } else {
          devise2fa = new Devise2fa($(this), options);
          $(this).data('devise2fa', devise2fa);
        }

        // call the method if it's public
        if (options.method && options.method.substr(0,1) !== '_') {
          return __guardMethod__(devise2fa, options.method, function(o, m) {
            return o[m]();
          });
        }
      });
    };
  })
})(jQuery);

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}
